.steps {
  width: 100%;
  margin-top: 2rem;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  align-items: center;
  justify-items: center;
  color: #c0c0c0;
  font-weight: bold;
}
.steps > div {
  width: 10rem;
  padding: 1rem;
  text-align: center;
  border-top: 0.3rem #c0c0c0 solid;
}

.steps > div.active {
  border-top: 0.3rem #f08000 solid;
  color: #f08000;
}

@media (max-width: 500px) {
  .steps {
    width: 100%;
    margin-top: 1rem;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.3rem;
    align-items: center;
    justify-items: center;
    color: #c0c0c0;
    font-weight: bold;
  }
  .steps > div {
    width: 3rem;
    padding: 0.5rem;
    text-align: center;
    border-top: 0.3rem #c0c0c0 solid;
  }
}
