.active {
  color: green;
  font-size: 0.9rem;
  font-weight: bold;
}

.passive {
  color: rgb(107, 108, 107);
  font-size: 0.9rem;
  font-weight: bold;
}

select {
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
}

.review {
  border-radius: 0.5rem;
  background-color: #a4a4a4;
  margin-bottom: 1.5rem;
  padding: 1rem;
}

.comment-alert {
  margin-top: 1.5rem;
  background-color: #5f8686;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.3rem;
}

.no-review {
  margin-top: 1.5rem;
  background-color: #ffe6e6;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.3rem;
}
